import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './contexts/AuthContext';
import LoginPage from './pages/LoginPage';
import ScannerPage from './pages/ScannerPage';


const AuthRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<Navigate to='/'/>} />
      <Route path="/" element={<LoginPage/>} />
    </Routes>
  </BrowserRouter>
)

const ScannerRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<Navigate to='/'/>} />
      <Route path="/" element={<ScannerPage/>} />
    </Routes>
  </BrowserRouter>
)

function App(){
  const {getAccountData} = useAuthContext();
  return getAccountData() ? <ScannerRoutes /> : <AuthRoutes />;
}

export default App;
