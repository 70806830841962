import React from 'react'
import {MdClose} from 'react-icons/md'

export default function ErrorAlert({title, message}) {
  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">{title} </strong>
        <span className="block sm:inline">{message}</span>
    </div>
  )
}
