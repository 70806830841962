import React, { createContext, useState, useContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const setAccountData = (value) => {
        sessionStorage.setItem('scannerData', JSON.stringify(value));
    };

    const getAccountData = () => {
        var item_value = sessionStorage.getItem('scannerData', );
        return item_value ? JSON.parse(item_value) : null;
    };

    return (
        <AuthContext.Provider value={{setAccountData, getAccountData}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext);