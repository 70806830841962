import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useZxing } from "react-zxing";
import { TRANSACTION_URL } from "../utils/constants";
import {HiOutlineRefresh} from 'react-icons/hi'
import ErrorAlert from "./ErrorAlert";
import Loader from "./Loader";
import ButtonAction from "./ButtonAction";

export const BarcodeScanner = () => {
  const [barcodeResult, setBarcodeResult] = useState(null);
  const [transactionData, setTransactionData] = useState(null);
  const [error, setError] = useState(null);
  const [loading,setLoading] = useState(false);
  const [scanComplete, setScanComplete] = useState(false);

  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);

  const { ref } = useZxing({
    onResult(result) {
      setBarcodeResult(result.getText());
    },
  });

  const invalidateTicket = () => {
    setLoading(true);
    axios.get(`${TRANSACTION_URL}/V1/Orders/MarkExpired?transactionId=${barcodeResult}&adminPasscode=admin:passcode`)
    .then(res => {
      setLoading(false);
      setScanComplete(true);
    })
    .catch(err => {
      setError(JSON.stringify(err.message));
      setLoading(false);
    })
  }

  const reload = () =>{
    window.location.reload();
  }

  useEffect(() =>{
    if(barcodeResult != null){
      setLoading(true);
      axios.get(`${TRANSACTION_URL}/V1/Orders/${barcodeResult}`)
      .then(res => {
        const data = res.data;
        console.log(data);
        setTransactionData(data);
        setLoading(false);
      })
      .catch(err => {
        setError(JSON.stringify(err.message));
        setLoading(false);
      })
    }
  },[barcodeResult]);

  return (
    <div className="flex flex-col h-full">
      <h1 className="text-center text-lg font-bold mt-4 uppercase">Scan Ticket</h1>

      <div className="flex flex-col justify-center items-center p-4">
        <video width={windowWidth*3/4} height={windowWidth*3/4} className="rounded-sm" ref={ref} />
      </div>

      <div className="flex flex-row justify-center items-center">
        <button onClick={reload} className="w-8">
          <HiOutlineRefresh className="text-2xl" />
        </button>
      </div>

      {error && <ErrorAlert message={error} />}
        {loading && <Loader />}

        {scanComplete && 
        <div>
        <span className="text-center font-extrabold text-xl py-8 text-blue-600 p-4">Ticket Validation Complete</span>
        <ButtonAction className={"w-32"} onClick={() => reload()} text={"Scan New Ticket"} />
        </div>}
      {error && <span className="font-extrabold text-xl text-red-600 p-4">Error fetching transaction data, refresh page to try again</span>}

      {!scanComplete && barcodeResult && transactionData &&
        <>
            <div className="p-4">
              <h2 className="mb-2 border-b-2 border-black">
                <span className="font-bold uppercase">Ticket Details: </span>
                <span>(</span>
                <span className="italic">STATUS: </span> 
                {transactionData?.isExpired ? 
                  <span className="font-extrabold text-xl text-red-600 mb-2">EXPIRED</span>
                  :
                  <span className="font-extrabold text-xl text-blue-800 mb-2">STILL VALID</span>
                }
                
                <span>)</span>
              </h2>
              
                <div className="border-b-2 border-black pb-2">
                  <p className="text-xs font-bold uppercase mb-2">ID #: {barcodeResult}</p>
                  <div className="">
                    <p className="italic font-bold text-lg">DESC.</p> 
                    <p className="font-bold mb-2">{transactionData?.productDescription}</p>
                  </div>

                  <p><span className="font-bold">Name:</span> <span>{transactionData?.accountName}</span></p>
                  <p><span className="font-bold">Tel:</span> <span>{transactionData?.accountMSISDN}</span></p>
                  <p><span className="font-bold">Email:</span> <span>{transactionData?.accountEmail}</span></p>
                  <p><span className="font-bold">Amount Paid:</span> <span>{transactionData?.amountCharged}</span></p>
                  <p><span className="font-bold">Payment Method:</span> <span>{transactionData?.paymentProvider}</span></p>
                </div>
            </div>
            {transactionData?.isExpired ? 
              <span className="bg-gray-500 text-black font-bold py-2 px-4 text-center my-4">
              TICKET EXPIRED
              </span>
              :
              <button onClick={invalidateTicket} className="bg-black text-white font-bold py-2 px-4 text-center my-4">
                INVALIDATE TICKET
              </button>
            }
            
        </>
        }
        { 
          !barcodeResult && !loading && !error && <span className="p-4 font-bold text-lg text-red-600">Scan QR Code on receipt to view ticket details</span>
        }
    </div>
  );
};